import './styles/index.styl';
import scrollMonitor from 'scrollmonitor';
import SmoothScroll from 'smooth-scroll/dist/js/smooth-scroll.polyfills';
import { query, addClass, removeClass } from './utils/dom';

const sectionTimers = {};

query('.section').forEach((section, index) => {
  const watcher = scrollMonitor.create(section);
  watcher.enterViewport(() => {
    clearTimeout(sectionTimers[index]);
    sectionTimers[index] = setTimeout(() => {
      addClass(section, 'enter');
    }, 300);
  });
  watcher.exitViewport(() => {
    clearTimeout(sectionTimers[index]);
    removeClass(section, 'enter');
  });
});

new SmoothScroll('a[href*="#"]');
